"use client";

import type React from "react";
import { useState, useContext } from "react";
import {
  Box,
  Card,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Fade,
  Divider,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Alert,
  Snackbar,
} from "@mui/material";
import { AuthContext } from "../../api/AuthProvider"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
import LoaderCenter from "../../common/LoaderCenter";
import GoogleLoginButton from "../../api/GoogleLoginButton";
import { Visibility, VisibilityOff, Email, Lock } from "@mui/icons-material";

interface LoginProps {
  setLoginOrSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<LoginProps> = ({ setLoginOrSignup }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "error" | "success" | "info" | "warning"
  >("error");

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Validate email format
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Handle form validation
  const validateForm = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    setIsButtonLoading(true);
    setShowLoader(true);

    try {
      const response = await login(email, password);
      if (response.status === 200) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Login successful!");
        setSnackbarOpen(true);

        // Short delay for better UX
        setTimeout(() => {
          navigate("/auth/aamain");
        }, 1000);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("Login failed. Please check your credentials.");
        setSnackbarOpen(true);
        setShowLoader(false);
        setIsButtonLoading(false);
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarOpen(true);
      setShowLoader(false);
      setIsButtonLoading(false);
    }
  };

  // Handle Enter key press
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Fade in={true} timeout={800}>
      <Card
        sx={{
          bgcolor: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(10px)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
          width: isSmallScreen ? "90%" : isMediumScreen ? "90%" : "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", sm: "auto" },
          borderRadius: "20px",
          padding: { xs: "20px", sm: "40px" },
          margin: { xs: "20px 0", sm: "auto" },
          color: "white",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0 12px 40px rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        {showLoader && <LoaderCenter message={"Authenticating..."} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            Welcome Back
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "rgba(255, 255, 255, 0.7)", mb: 2 }}
          >
            Enter your credentials to access your account
          </Typography>

          <TextField
            fullWidth
            id="email"
            label="Email"
            variant="outlined"
            error={!!emailError}
            helperText={emailError}
            FormHelperTextProps={{ sx: { color: "error.main" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                </InputAdornment>
              ),
              autoComplete: "new-password",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "white" },
                "&.Mui-error fieldset": { borderColor: "error.main" },
              },
              "& input": { color: "white" },
              "& .MuiInputLabel-root": {
                color: "rgba(255, 255, 255, 0.7)",
                "&.Mui-focused": { color: "white" },
                "&.Mui-error": { color: "error.main" },
              },
              mb: 1,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />

          <TextField
            fullWidth
            id="password"
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            error={!!passwordError}
            helperText={passwordError}
            FormHelperTextProps={{ sx: { color: "error.main" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: "new-password",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "white" },
                "&.Mui-error fieldset": { borderColor: "error.main" },
              },
              "& input": { color: "white" },
              "& .MuiInputLabel-root": {
                color: "rgba(255, 255, 255, 0.7)",
                "&.Mui-focused": { color: "white" },
                "&.Mui-error": { color: "error.main" },
              },
              mb: 2,
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
          />

          <Button
            variant="contained"
            onClick={handleLogin}
            fullWidth
            disabled={isButtonLoading}
            sx={{
              backgroundColor: "rgba(25, 118, 210, 0.8)",
              color: "white",
              fontWeight: "bold",
              padding: "12px",
              borderRadius: "8px",
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(25, 118, 210, 1)",
                boxShadow: "0 4px 12px rgba(25, 118, 210, 0.4)",
              },
              transition: "all 0.3s ease",
            }}
          >
            {isButtonLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Login"
            )}
          </Button>

          <Box
            sx={{ width: "100%", display: "flex", alignItems: "center", my: 2 }}
          >
            <Divider
              sx={{ flexGrow: 1, borderColor: "rgba(255, 255, 255, 0.2)" }}
            />
            <Typography
              variant="body2"
              sx={{ px: 2, color: "rgba(255, 255, 255, 0.7)" }}
            >
              OR
            </Typography>
            <Divider
              sx={{ flexGrow: 1, borderColor: "rgba(255, 255, 255, 0.2)" }}
            />
          </Box>

          <GoogleLoginButton />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              onClick={() => setLoginOrSignup(false)}
              fullWidth
              sx={{
                color: "white",
                fontWeight: "bold",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "8px",
                padding: "10px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
                transition: "all 0.3s ease",
              }}
            >
              Create an Account
            </Button>

            <Button
              onClick={() => navigate("/forgot-password")}
              sx={{
                width: "100%",
                color: "rgba(255, 255, 255, 0.7)",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "white",
                  textDecoration: "underline",
                },
              }}
            >
              Forgot your password?
            </Button>
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Card>
    </Fade>
  );
};

export default Login;
