"use client";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
  useTheme,
  Chip,
  alpha,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  Code,
  Lightbulb,
  Target,
  CheckCircle,
  ArrowRight,
  Monitor,
  Database,
  Shield,
  Cloud,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

// Wrap Material UI components with motion
const MotionBox = motion(Box);
const MotionGrid = motion(Grid);
const MotionCard = motion(Card);
const MotionTypography = motion(Typography);

const AboutPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleContactUsButton = () => {
    navigate("/help");
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  // Team members data
  interface TeamMember {
    role: string;
    avatar: string;
    specialty?: string; // Optional property
    name: string;
  }
  const teamMembers: TeamMember[] = [];

  // Services data
  const services = [
    {
      title: "Web Development",
      description:
        "Custom web applications built with cutting-edge technologies to solve complex business challenges.",
      icon: <Monitor size={32} />,
      color: "#4361ee",
    },
    {
      title: "Database Solutions",
      description:
        "Optimized database architectures that ensure data integrity, security, and performance.",
      icon: <Database size={32} />,
      color: "#3a0ca3",
    },
    {
      title: "Cybersecurity",
      description:
        "Comprehensive security solutions to protect your digital assets from evolving threats.",
      icon: <Shield size={32} />,
      color: "#7209b7",
    },
    {
      title: "Cloud Integration",
      description:
        "Seamless cloud migration and infrastructure management for scalable, reliable operations.",
      icon: <Cloud size={32} />,
      color: "#f72585",
    },
  ];

  return (
    <Box
      component="main"
      sx={{
        minHeight: "100vh",
        background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        overflow: "hidden",
      }}
    >
      {/* Background elements */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          overflow: "hidden",
          opacity: 0.05,
        }}
      >
        {Array.from({ length: 20 }).map((_, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              position: "absolute",
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 20 + 10}px`,
              opacity: Math.random() * 0.5 + 0.5,
              transform: `rotate(${Math.random() * 360}deg)`,
              color: theme.palette.primary.main,
              fontFamily: "monospace",
            }}
          >
            {"{"}
          </Box>
        ))}
        {Array.from({ length: 20 }).map((_, index) => (
          <Box
            key={`code-${index}`}
            component="span"
            sx={{
              position: "absolute",
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 20 + 10}px`,
              opacity: Math.random() * 0.5 + 0.5,
              transform: `rotate(${Math.random() * 360}deg)`,
              color: theme.palette.secondary.main,
              fontFamily: "monospace",
            }}
          >
            {"}"}
          </Box>
        ))}
      </Box>

      {/* Hero Section */}
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          py: { xs: 10, md: 15 },
          px: 3,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backgroundImage: "url('/placeholder.svg?height=600&width=1200')",
          backgroundBlendMode: "overlay",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      >
        <Container maxWidth="lg">
          <MotionTypography
            variant="overline"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            sx={{
              mb: 2,
              fontWeight: 600,
              letterSpacing: 3,
              color: theme.palette.primary.light,
            }}
          >
            ABOUT US
          </MotionTypography>

          <MotionTypography
            variant="h2"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.8 }}
            sx={{
              mb: 3,
              fontWeight: 800,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: "text",
              textFillColor: "transparent",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            We Transform IT Challenges Into Solutions
          </MotionTypography>

          <MotionTypography
            variant="h6"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.8 }}
            sx={{
              mb: 6,
              maxWidth: "800px",
              mx: "auto",
              fontWeight: 400,
              lineHeight: 1.6,
              color: "rgba(255, 255, 255, 0.9)",
            }}
          >
            A collective of innovative developers and problem solvers dedicated
            to building cutting-edge digital solutions that drive business
            growth and technological advancement.
          </MotionTypography>

          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.8 }}
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowRight />}
              sx={{
                borderRadius: "50px",
                px: 4,
                py: 1.5,
                fontWeight: 600,
                textTransform: "none",
                fontSize: "1rem",
                boxShadow: theme.shadows[10],
              }}
            >
              Our Projects
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={handleContactUsButton}
              sx={{
                borderRadius: "50px",
                px: 4,
                py: 1.5,
                fontWeight: 600,
                textTransform: "none",
                fontSize: "1rem",
                borderColor: "rgba(255, 255, 255, 0.5)",
                color: "white",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              Contact Us
            </Button>
          </MotionBox>
        </Container>
      </MotionBox>

      {/* Mission Section */}
      <Container
        maxWidth="lg"
        sx={{ py: { xs: 8, md: 12 }, position: "relative" }}
      >
        <MotionGrid
          container
          spacing={6}
          alignItems="center"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <Grid item xs={12} md={6}>
            <MotionBox
              variants={itemVariants}
              sx={{
                position: "relative",
                height: { xs: "300px", md: "450px" },
                borderRadius: "20px",
                overflow: "hidden",
                boxShadow: theme.shadows[10],
              }}
            >
              <Box
                component="img"
                src="/ourMission.jpeg?height=520&width=600"
                alt="Our mission"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "50%",
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
                  display: "flex",
                  alignItems: "flex-end",
                  p: 3,
                }}
              >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  Our Mission
                </Typography>
              </Box>
            </MotionBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MotionBox variants={itemVariants}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  fontSize: { xs: "2rem", md: "2.5rem" },
                }}
              >
                Solving Complex IT Challenges Through Innovation
              </Typography>

              <Typography
                variant="body1"
                paragraph
                sx={{
                  mb: 4,
                  fontSize: "1.1rem",
                  lineHeight: 1.7,
                  color: theme.palette.text.secondary,
                }}
              >
                At our core, we're problem solvers and innovators. We identify
                critical IT challenges that businesses face and develop elegant,
                efficient solutions that drive growth and enhance operational
                efficiency.
              </Typography>

              <Grid container spacing={3}>
                {[
                  {
                    icon: <Lightbulb size={24} />,
                    text: "Innovative Thinking",
                  },
                  { icon: <Code size={24} />, text: "Technical Excellence" },
                  { icon: <Target size={24} />, text: "Solution-Focused" },
                  {
                    icon: <CheckCircle size={24} />,
                    text: "Quality Assurance",
                  },
                ].map((item, index) => (
                  <Grid item xs={6} key={index}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Box
                        sx={{
                          mr: 2,
                          color: theme.palette.primary.main,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography variant="body1" fontWeight={500}>
                        {item.text}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </MotionBox>
          </Grid>
        </MotionGrid>
      </Container>

      {/* Services Section */}
      <Box
        sx={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? alpha(theme.palette.background.paper, 0.6)
              : alpha(theme.palette.grey[100], 0.6),
          py: { xs: 8, md: 12 },
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <MotionBox
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            sx={{ textAlign: "center", mb: 8 }}
          >
            <MotionTypography
              variant="overline"
              variants={itemVariants}
              sx={{
                mb: 2,
                fontWeight: 600,
                letterSpacing: 3,
                color: theme.palette.primary.main,
              }}
            >
              OUR EXPERTISE
            </MotionTypography>

            <MotionTypography
              variant="h3"
              variants={itemVariants}
              sx={{
                mb: 3,
                fontWeight: 700,
                fontSize: { xs: "2rem", md: "2.5rem" },
              }}
            >
              Solutions We Provide
            </MotionTypography>

            <MotionTypography
              variant="body1"
              variants={itemVariants}
              sx={{
                mb: 6,
                maxWidth: "800px",
                mx: "auto",
                fontSize: "1.1rem",
                color: theme.palette.text.secondary,
              }}
            >
              We specialize in developing tailored IT solutions that address
              specific business challenges, enhance operational efficiency, and
              drive digital transformation.
            </MotionTypography>
          </MotionBox>

          <MotionGrid
            container
            spacing={4}
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <MotionCard
                  variants={itemVariants}
                  whileHover={{
                    y: -10,
                    boxShadow: theme.shadows[10],
                    transition: { duration: 0.3 },
                  }}
                  sx={{
                    height: "100%",
                    borderRadius: "16px",
                    overflow: "hidden",
                    boxShadow: theme.shadows[4],
                    transition: "all 0.3s ease",
                    border: "1px solid",
                    borderColor: theme.palette.divider,
                  }}
                >
                  <CardContent
                    sx={{
                      p: 4,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        mb: 3,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 70,
                        height: 70,
                        borderRadius: "16px",
                        backgroundColor: alpha(service.color, 0.1),
                        color: service.color,
                      }}
                    >
                      {service.icon}
                    </Box>

                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      sx={{ fontWeight: 600, mb: 2 }}
                    >
                      {service.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        lineHeight: 1.7,
                        flexGrow: 1,
                      }}
                    >
                      {service.description}
                    </Typography>

                    <Button
                      variant="text"
                      endIcon={<ArrowRight size={16} />}
                      sx={{
                        mt: 3,
                        alignSelf: "flex-start",
                        textTransform: "none",
                        fontWeight: 600,
                        p: 0,
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: service.color,
                        },
                      }}
                    >
                      Learn more
                    </Button>
                  </CardContent>
                </MotionCard>
              </Grid>
            ))}
          </MotionGrid>
        </Container>
      </Box>

      {/* Team Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 8, md: 12 } }}>
        <MotionBox
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          sx={{ textAlign: "center", mb: 8 }}
        >
          <MotionTypography
            variant="overline"
            variants={itemVariants}
            sx={{
              mb: 2,
              fontWeight: 600,
              letterSpacing: 3,
              color: theme.palette.primary.main,
            }}
          >
            OUR TEAM
          </MotionTypography>

          <MotionTypography
            variant="h3"
            variants={itemVariants}
            sx={{
              mb: 3,
              fontWeight: 700,
              fontSize: { xs: "2rem", md: "2.5rem" },
            }}
          >
            Meet The Problem Solvers
          </MotionTypography>

          <MotionTypography
            variant="body1"
            variants={itemVariants}
            sx={{
              mb: 6,
              maxWidth: "800px",
              mx: "auto",
              fontSize: "1.1rem",
              color: theme.palette.text.secondary,
            }}
          >
            Our diverse team brings together expertise from various IT
            disciplines, united by a passion for solving complex technical
            challenges and delivering exceptional results.
          </MotionTypography>
        </MotionBox>

        <MotionGrid
          container
          spacing={4}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
        >
          {teamMembers?.map((member, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <MotionCard
                variants={itemVariants}
                whileHover={{
                  y: -10,
                  boxShadow: theme.shadows[10],
                  transition: { duration: 0.3 },
                }}
                sx={{
                  height: "100%",
                  borderRadius: "16px",
                  overflow: "hidden",
                  boxShadow: theme.shadows[4],
                  transition: "all 0.3s ease",
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                }}
              >
                <Box sx={{ pt: 4, px: 4 }}>
                  <Avatar
                    src={member.avatar}
                    alt={member.name}
                    sx={{
                      width: 120,
                      height: 120,
                      mx: "auto",
                      mb: 3,
                      border: "4px solid",
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Box>

                <CardContent sx={{ p: 4 }}>
                  <Typography
                    variant="h6"
                    component="h3"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    {member.name}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      mb: 2,
                    }}
                  >
                    {member.role}
                  </Typography>

                  <Chip
                    label={member.specialty}
                    size="small"
                    sx={{
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      color: theme.palette.primary.main,
                      fontWeight: 500,
                    }}
                  />
                </CardContent>
              </MotionCard>
            </Grid>
          ))}
        </MotionGrid>
      </Container>

      {/* Testimonial Section */}
      <Box
        sx={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? alpha(theme.palette.background.paper, 0.6)
              : alpha(theme.palette.grey[100], 0.6),
          py: { xs: 8, md: 12 },
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <MotionBox
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            <MotionCard
              variants={itemVariants}
              sx={{
                borderRadius: "24px",
                overflow: "hidden",
                boxShadow: theme.shadows[10],
                background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                color: "white",
                position: "relative",
              }}
            >
              <CardContent sx={{ p: { xs: 4, md: 6 } }}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12} md={7}>
                    <Typography
                      variant="h2"
                      component="div"
                      sx={{
                        fontWeight: 800,
                        mb: 3,
                        fontSize: { xs: "2.5rem", md: "3rem" },
                        lineHeight: 1.2,
                      }}
                    >
                      "They transformed our outdated systems into a powerful
                      digital ecosystem."
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        mb: 4,
                        fontSize: "1.1rem",
                        opacity: 0.9,
                        lineHeight: 1.7,
                      }}
                    >
                      The team didn't just solve our immediate IT problems—they
                      anticipated future challenges and built scalable solutions
                      that have grown with our business. Their technical
                      expertise and strategic approach have been invaluable to
                      our digital transformation journey.
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src="/sundarPichai.jpeg?height=60&width=60"
                        alt="Sundar Pichai"
                        sx={{
                          width: 60,
                          height: 60,
                          mr: 2,
                          border: "2px solid white",
                        }}
                      />
                      <Box>
                        <Typography variant="h6" fontWeight={600}>
                          Sundar Pichai
                        </Typography>
                        <Typography variant="body2" sx={{ opacity: 0.9 }}>
                          CEO, Google.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: "400px",
                        borderRadius: "16px",
                        overflow: "hidden",
                        boxShadow: theme.shadows[10],
                      }}
                    >
                      <Box
                        component="img"
                        src="/clientSuccessStory.jpeg?height=400&width=400"
                        alt="Client success story"
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </MotionCard>
          </MotionBox>
        </Container>
      </Box>

      {/* CTA Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 8, md: 12 } }}>
        <MotionBox
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          sx={{ textAlign: "center" }}
        >
          <MotionTypography
            variant="h3"
            variants={itemVariants}
            sx={{
              mb: 3,
              fontWeight: 700,
              fontSize: { xs: "2rem", md: "2.5rem" },
            }}
          >
            Ready to Solve Your IT Challenges?
          </MotionTypography>

          <MotionTypography
            variant="body1"
            variants={itemVariants}
            sx={{
              mb: 6,
              maxWidth: "800px",
              mx: "auto",
              fontSize: "1.1rem",
              color: theme.palette.text.secondary,
            }}
          >
            Let's discuss how our team of expert developers can help transform
            your technical challenges into innovative solutions that drive your
            business forward.
          </MotionTypography>

          <MotionBox
            variants={itemVariants}
            sx={{
              display: "flex",
              gap: 3,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowRight />}
              sx={{
                borderRadius: "50px",
                px: 4,
                py: 1.5,
                fontWeight: 600,
                textTransform: "none",
                fontSize: "1rem",
                boxShadow: theme.shadows[10],
              }}
            >
              Schedule a Consultation
            </Button>
            <Button
              variant="outlined"
              size="large"
              sx={{
                borderRadius: "50px",
                px: 4,
                py: 1.5,
                fontWeight: 600,
                textTransform: "none",
                fontSize: "1rem",
              }}
            >
              View Our Case Studies
            </Button>
          </MotionBox>
        </MotionBox>
      </Container>
    </Box>
  );
};

export default AboutPage;
