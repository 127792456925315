import React, { useState, useEffect } from "react";
import { Box, Slide, Typography, useMediaQuery } from "@mui/material";
import TypingGreeting from "../../common/TypingGreetings";
import Login from "./Login";
import Signup from "./Signup";

const useStyles = {
  backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust transparency here (0.5 means 50% transparency)
  boxShadow: "none",
};

type ImageType = {
  imgName: string;
  imgText: string;
};

interface AuthenticationProps {
  images: ImageType[];
  displayMessage: string;
}

const Authentication: React.FC<AuthenticationProps> = ({
  images,
  displayMessage,
}) => {
  const [loginOrSignup, setLoginOrSignup] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [checked, setChecked] = useState(true);

  // Media query to detect if the screen is smaller than 600px (adjust based on your needs)
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const interval = setInterval(() => {
      setChecked(false); // Start the slide-out animation
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setChecked(true); // Start the slide-in animation
      }, 500); // Adjust this timeout to match the Slide transition duration
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Switch layout based on screen size
        width: "100%",
        position: "relative", // Ensure parent has relative positioning
        margin: "7% 0% 0% 0%",
      }}
    >
      {/* Left Box */}
      {!isMobile && (
        <Box
          sx={{
            flex: 1,
            backgroundPosition: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box>
            <TypingGreeting text={displayMessage} duration={15000} />
          </Box>
          {images.map((data, index) => (
            <Slide
              key={index}
              direction="right"
              in={checked && currentImageIndex === index}
              timeout={1000}
              mountOnEnter
              unmountOnExit
            >
              <Box
                sx={{
                  marginTop: "10%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "absolute", // Ensure it's positioned absolutely
                  top: "25%",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 0, // Adjust zIndex to ensure it's above other content
                }}
              >
                {!isMobile && (
                  <Box
                    component="img"
                    src={data.imgName}
                    alt={`Slide ${index}`}
                    sx={{
                      width: isMobile ? "20%" : "50%", // Adjusted for better responsiveness
                      height: isMobile ? "25%" : "50%",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    marginTop: "60px", // Reduced margin to avoid collision
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "modern-sans, Arial, sans-serif",
                    fontSize: isMobile ? "0.7rem" : "1.2rem", // Adjusted font size
                    width: "80%", // Adjusted width
                    textAlign: "center",
                  }}
                >
                  {data.imgText}
                </Typography>
              </Box>
            </Slide>
          ))}
        </Box>
      )}
      {/* Right Box */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundPosition: "50% 50%",
          backgroundSize: "1600px",
          backgroundRepeat: "repeat",
          position: "relative",
          zIndex: 0,
        }}
      >
        {loginOrSignup ? (
          <Login setLoginOrSignup={setLoginOrSignup} />
        ) : (
          <Signup setLoginOrSignup={setLoginOrSignup} />
        )}
      </Box>
    </Box>
  );
};

export default Authentication;
