import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

const RotatingQuestionMark = ({ onClick }) => {
  const [marginTop, setMarginTop] = useState("4%");
  const [width, setWidth] = useState("50%");
  const [height, setHeight] = useState("80%");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setMarginTop("4%");
        setWidth("50%");
        setHeight("80%");
      } else {
        setMarginTop("1%");
        setWidth("40%");
        setHeight("40%");
      }
    }; // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Set initial font size
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{ marginTop: { marginTop }, width: { width }, height: { height } }}
      onClick={onClick}
    >
      <img
        src="/questionMark.gif" // GIF in public folder
        alt="Rotating Question Mark"
        style={{
          width: "30%",
          height: "30%",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};

export default RotatingQuestionMark;
