import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AutoApplyAuthPage from "./pages/apps/autoApply/AutoApplyAuthPage";
import Layout from "./common/Layout";
import AutoApplyMainPage from "./pages/apps/autoApply/AutoApplyMainPage";
import HelpPage from "./pages/HelpPage";
import AboutPage from "./pages/AboutPage";
import AutoApplyHomePage from "./pages/apps/autoApply/AutoApplyHomePage";
import {
  TextField,
  createTheme,
  ThemeProvider,
  CssBaseline,
  GlobalStyles,
} from "@mui/material";
import BuyUsCoffee from "./pages/BuyUsCoffee";

const App: React.FC = () => {
  const isDarkMode = true;

  // Theme customization with autofill override
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      primary: {
        main: "#7c4dff", // Lighter purple
      },
      secondary: {
        main: "#9C27B0", // Teal
      },
      background: {
        default: isDarkMode ? "rgba(0, 0, 0, 0.4)" : "#ffffff",
        paper: isDarkMode ? "rgba(0, 0, 0, 0.4)" : "#ffffff",
      },
      text: {
        primary: isDarkMode ? "#ffffff" : "#000000",
        secondary: isDarkMode ? "#bdbdbd" : "#555555",
      },
    },
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              WebkitTextFillColor: "7c4dff",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus":
            {
              backgroundColor: isDarkMode
                ? "#9C27B0 !important"
                : "#f3e5f5 !important",
              boxShadow: "0 0 0px 1000px transparent inset !important",
              WebkitTextFillColor: isDarkMode ? "#ffffff" : "#000000",
              transition: "background-color 5000s ease-in-out 0s",
            },
        }}
      />
      <Layout>
        <Routes>
          <Route path="/about" Component={AboutPage}></Route>
          <Route path="/auth" Component={AutoApplyAuthPage}></Route>
          <Route path="/" Component={AutoApplyHomePage}></Route>
          <Route path="/home" Component={AutoApplyHomePage}></Route>
          <Route path="/help" Component={HelpPage}></Route>
          <Route path="/buyUsCoffee" Component={BuyUsCoffee}></Route>
          <Route
            path="/auth/aamain"
            element={<PrivateRoute element={AutoApplyMainPage} />}
          ></Route>
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
