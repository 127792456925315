"use client";

import type React from "react";
import { useState } from "react";
import {
  Box,
  Card,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Typography,
  Divider,
  Fade,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  PersonOutline,
  EmailOutlined,
  LockOutlined,
  ArrowForward,
  CheckCircleOutline,
} from "@mui/icons-material";
import LoaderCenter from "../../common/LoaderCenter";
import SignUpGate from "./SignupGate";
import { otpCall } from "../../api/RapidData";
import GoogleLoginButton from "../../api/GoogleLoginButton";

interface LoginProps {
  setLoginOrSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Signup: React.FC<LoginProps> = ({ setLoginOrSignup }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Password strength checker
  const checkPasswordStrength = (pass: string) => {
    let strength = 0;
    if (pass.length >= 8) strength += 1;
    if (/[A-Z]/.test(pass)) strength += 1;
    if (/[0-9]/.test(pass)) strength += 1;
    if (/[^A-Za-z0-9]/.test(pass)) strength += 1;
    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);

    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setPasswordError("");
    }

    if (confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else if (confirmPassword) {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const confirmPass = e.target.value;
    setConfirmPassword(confirmPass);
    if (confirmPass !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFocus = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    clearError = true,
  ) => {
    if (clearError) setter("");
  };

  const handleOpenOtpModal = () => {
    setShowOtpScreen(true);
  };

  const handleCloseOtpModal = () => {
    setShowOtpScreen(false);
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSignup = () => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");

    let hasError = false;

    if (!name) {
      setNameError("Name is required");
      hasError = true;
    }

    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    }

    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      hasError = true;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      hasError = true;
    }

    if (!hasError) {
      setShowLoader(true);
      otpCall(email)
        .then((response: any) => {
          if (response.status === 200) {
            setShowOtpScreen(true);
          } else {
            setShowOtpScreen(false);
          }
          setShowLoader(false);
        })
        .catch(() => {
          setShowLoader(false);
          setShowOtpScreen(false);
        });
    }
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
        return "#f44336"; // Red
      case 1:
        return "#ff9800"; // Orange
      case 2:
        return "#ffeb3b"; // Yellow
      case 3:
        return "#4caf50"; // Light Green
      case 4:
        return "#2e7d32"; // Dark Green
      default:
        return "#f44336";
    }
  };

  const getPasswordStrengthText = () => {
    switch (passwordStrength) {
      case 0:
        return "Weak";
      case 1:
        return "Fair";
      case 2:
        return "Good";
      case 3:
        return "Strong";
      case 4:
        return "Very Strong";
      default:
        return "Weak";
    }
  };

  return (
    <Fade in={true} timeout={800}>
      <Card
        sx={{
          bgcolor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(16px)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
          color: "white",
          width: isSmallScreen ? "90%" : isMediumScreen ? "90%" : "80%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "65vh",
          padding: isSmallScreen ? "24px 16px" : "32px",
          borderRadius: "24px",
          margin: "auto",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0 12px 40px rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        {showLoader && <LoaderCenter message={"Verifying your details..."} />}
        {showOtpScreen && (
          <SignUpGate
            password={password}
            email={email}
            name={name}
            setShowLoader={setShowLoader}
            setShowOtpScreen={setShowOtpScreen}
            open={showOtpScreen}
            onClose={handleCloseOtpModal}
          />
        )}

        <Avatar
          sx={{
            width: 64,
            height: 64,
            bgcolor: "primary.main",
            mb: 2,
          }}
        >
          <PersonOutline fontSize="large" />
        </Avatar>

        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: 600, mb: 3 }}
        >
          Create Account
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2.5,
            width: "100%",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={Boolean(nameError)}
            helperText={nameError}
            onFocus={() => handleFocus(setNameError)}
            InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.3)" },
                "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                "&.Mui-focused fieldset": { borderColor: "primary.main" },
                borderRadius: "12px",
              },
              "& input": { color: "white", padding: "14px 14px 14px 4px" },
              "& .MuiFormHelperText-root": {
                color: "error.light",
                marginLeft: 0,
                marginTop: 0.5,
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(emailError)}
            helperText={emailError}
            onFocus={() => handleFocus(setEmailError)}
            InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.3)" },
                "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                "&.Mui-focused fieldset": { borderColor: "primary.main" },
                borderRadius: "12px",
              },
              "& input": { color: "white", padding: "14px 14px 14px 4px" },
              "& .MuiFormHelperText-root": {
                color: "error.light",
                marginLeft: 0,
                marginTop: 0.5,
              },
            }}
          />

          <TextField
            autoComplete="new-password"
            id="outlined-password"
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={showPassword ? "Hide password" : "Show password"}
                  >
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                      sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.3)" },
                "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                "&.Mui-focused fieldset": { borderColor: "primary.main" },
                borderRadius: "12px",
              },
              "& input": { color: "white", padding: "14px 14px 14px 4px" },
              "& .MuiFormHelperText-root": {
                color: "error.light",
                marginLeft: 0,
                marginTop: 0.5,
              },
            }}
            error={Boolean(passwordError)}
            helperText={passwordError}
            onFocus={() => handleFocus(setPasswordError)}
          />

          {password && (
            <Box sx={{ width: "100%", mb: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 0.5,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ color: getPasswordStrengthColor() }}
                >
                  Password strength: {getPasswordStrengthText()}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: "rgba(255, 255, 255, 0.5)" }}
                >
                  {passwordStrength}/4
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "4px",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "2px",
                }}
              >
                <Box
                  sx={{
                    width: `${(passwordStrength / 4) * 100}%`,
                    height: "100%",
                    bgcolor: getPasswordStrengthColor(),
                    borderRadius: "2px",
                    transition: "width 0.3s ease, background-color 0.3s ease",
                  }}
                />
              </Box>
            </Box>
          )}

          <TextField
            id="outlined-confirm-password"
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined sx={{ color: "rgba(255, 255, 255, 0.7)" }} />
                </InputAdornment>
              ),
              endAdornment:
                confirmPassword && confirmPassword === password ? (
                  <InputAdornment position="end">
                    <CheckCircleOutline sx={{ color: "#4caf50" }} />
                  </InputAdornment>
                ) : null,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.3)" },
                "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                "&.Mui-focused fieldset": { borderColor: "primary.main" },
                borderRadius: "12px",
              },
              "& input": { color: "white", padding: "14px 14px 14px 4px" },
              "& .MuiFormHelperText-root": {
                color: "error.light",
                marginLeft: 0,
                marginTop: 0.5,
              },
            }}
            error={Boolean(confirmPasswordError)}
            helperText={confirmPasswordError}
            onFocus={() => handleFocus(setConfirmPasswordError)}
          />

          <Button
            variant="contained"
            onClick={handleSignup}
            fullWidth
            disabled={showLoader}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              fontWeight: "bold",
              padding: "12px",
              borderRadius: "12px",
              textTransform: "none",
              fontSize: "16px",
              mt: 1,
              "&:hover": {
                backgroundColor: "primary.dark",
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(255, 255, 255, 0.12)",
              },
            }}
            endIcon={
              showLoader ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <ArrowForward />
              )
            }
          >
            {showLoader ? "Processing..." : "Create Account"}
          </Button>

          <Divider
            sx={{
              width: "100%",
              my: 2,
              "&::before, &::after": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            or
          </Divider>

          <GoogleLoginButton />

          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography
              variant="body2"
              sx={{ color: "rgba(255, 255, 255, 0.7)" }}
            >
              Already have an account?{" "}
              <Button
                onClick={() => setLoginOrSignup(true)}
                sx={{
                  color: "primary.light",
                  fontWeight: "bold",
                  textTransform: "none",
                  p: 0,
                  ml: 0.5,
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                }}
              >
                Log in
              </Button>
            </Typography>
          </Box>
        </Box>
      </Card>
    </Fade>
  );
};

export default Signup;
