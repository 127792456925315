"use client";

import type React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  useTheme,
  IconButton,
  alpha,
} from "@mui/material";
import { otpCall } from "../../api/RapidData";
import { AuthContext } from "../../api/AuthProvider";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";

interface SignUpGateProps {
  password: string;
  email: string;
  name: string;
  setShowLoader: (show: boolean) => void;
  setShowOtpScreen: (show: boolean) => void;
  open: boolean; // New prop to control modal visibility
  onClose?: () => void; // Optional callback for closing the modal
}

const SignUpGate: React.FC<SignUpGateProps> = ({
  password,
  email,
  name,
  setShowLoader,
  setShowOtpScreen,
  open,
  onClose,
}) => {
  const [timer, setTimer] = useState(300);
  const [resenderTimer, setResenderTimer] = useState(30);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  // Reset state when modal opens
  useEffect(() => {
    if (open) {
      setOtp(Array(6).fill(""));
      setIsSubmitting(false);
    }
  }, [open]);

  useEffect(() => {
    // Only start the timer when the modal is open
    if (!open) return;

    // Focus the first input field when component mounts
    if (inputRefs.current[0]) {
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 100);
    }

    const countdown = setInterval(() => {
      setResenderTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, [open]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input field
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    // Handle backspace to move to previous input
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }

    // Handle Enter key to submit when on last input
    if (e.key === "Enter" && index === 5 && otp.every((digit) => digit)) {
      handleSubmit();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();

    // Check if pasted content is a 6-digit number
    if (/^\d{6}$/.test(pastedData)) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);

      // Focus the last input
      inputRefs.current[5]?.focus();
    }
  };

  const handleSubmit = () => {
    if (otp.some((digit) => digit === "")) {
      return; // Don't submit if OTP is incomplete
    }

    setIsSubmitting(true);
    setShowLoader(true);
    const otpString = otp.join("");

    signup(email, password, name, otpString)
      .then((response: any) => {
        if (response.status === 200) {
          navigate("/auth/aamain");
          setShowOtpScreen(false);
          if (onClose) onClose();
        } else {
          setShowOtpScreen(false);
          setShowLoader(false);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
        setShowOtpScreen(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleResender = () => {
    setShowLoader(true);
    otpCall(email)
      .then((response: any) => {
        if (response.status === 200) {
          setShowOtpScreen(true);
        } else {
          setShowOtpScreen(false);
        }
        setShowLoader(false);
      })
      .catch((error: any) => {
        setShowLoader(false);
        setShowOtpScreen(false);
      });
    setTimer(300);
    setResenderTimer(30);
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: alpha("#000", 0.75),
            backdropFilter: "blur(4px)",
          },
        },
      }}
      aria-labelledby="otp-verification-modal"
      aria-describedby="modal-to-verify-otp-code"
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "450px" },
            maxWidth: "100%",
            outline: "none",
          }}
        >
          <Paper
            elevation={24}
            sx={{
              width: "100%",
              borderRadius: 4,
              overflow: "hidden",
              background:
                theme.palette.mode === "dark"
                  ? alpha(theme.palette.background.paper, 0.95)
                  : theme.palette.background.paper,
              position: "relative",
              boxShadow: theme.shadows[24],
            }}
          >
            {/* Close button */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 70,
                  height: 70,
                  borderRadius: "50%",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                <LockIcon fontSize="large" />
              </Box>

              <Typography
                variant="h5"
                component="h2"
                fontWeight="bold"
                gutterBottom
              >
                Verification Required
              </Typography>

              <Typography
                variant="body1"
                color="text.secondary"
                align="center"
                sx={{ mb: 3 }}
              >
                We've sent a 6-digit code to <strong>{email}</strong>
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Code expires in:
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color={timer < 60 ? "error.main" : "primary.main"}
                  sx={{ ml: 1 }}
                >
                  {formatTime(timer)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: { xs: 1, sm: 1.5 },
                  width: "100%",
                  my: 3,
                }}
                onPaste={handlePaste}
              >
                {otp.map((digit, index) => (
                  <TextField
                    key={index}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    value={digit}
                    onChange={(e) =>
                      handleChange(
                        e as React.ChangeEvent<HTMLInputElement>,
                        index,
                      )
                    }
                    onKeyDown={(e) =>
                      handleKeyDown(
                        e as React.KeyboardEvent<HTMLInputElement>,
                        index,
                      )
                    }
                    variant="outlined"
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        padding: "12px 0",
                      },
                    }}
                    sx={{
                      width: { xs: "40px", sm: "48px" },
                      height: { xs: "50px", sm: "60px" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: digit
                            ? theme.palette.primary.main
                            : theme.palette.divider,
                          borderWidth: digit ? 2 : 1,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                  />
                ))}
              </Box>

              <Button
                variant="contained"
                size="large"
                fullWidth
                disabled={!isOtpComplete || isSubmitting}
                onClick={handleSubmit}
                sx={{
                  py: 1.5,
                  mb: 2,
                  borderRadius: 2,
                  textTransform: "none",
                  fontSize: "1rem",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verify & Continue"
                )}
              </Button>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Didn't receive the code?
                </Typography>
                <Button
                  disabled={resenderTimer > 0}
                  onClick={handleResender}
                  variant="text"
                  size="small"
                  sx={{
                    ml: 1,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                >
                  {resenderTimer > 0
                    ? `Resend in ${formatTime(resenderTimer)}`
                    : "Resend Code"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SignUpGate;
