"use client";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Stack,
  Divider,
} from "@mui/material";
import { Mail, Phone, MapPin } from "lucide-react";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        color: "white",
        width: "100%",
        // position: "relative",
        bottom: 0,
        left: 0,
        zIndex: 10,
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Linkedin Buddy
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, maxWidth: "90%" }}>
              Helping you streamline your application process with automated
              tools and resources.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Stack direction="row" spacing={2} flexWrap="wrap">
              <Link
                href="/"
                color="inherit"
                sx={{ mr: 2, display: "inline-block", mb: 1 }}
              >
                Home
              </Link>
              <Link
                href="/about"
                color="inherit"
                sx={{ mr: 2, display: "inline-block", mb: 1 }}
              >
                About
              </Link>
              <Link
                href="/help"
                color="inherit"
                sx={{ mr: 2, display: "inline-block", mb: 1 }}
              >
                Contact
              </Link>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Stack spacing={1}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Mail size={16} style={{ marginRight: 8 }} />
                <Typography variant="body2">
                  support@linkedinbuddy.com
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Phone size={16} style={{ marginRight: 8 }} />
                <Typography variant="body2">(123) 456-7890</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <MapPin size={16} style={{ marginRight: 8 }} />
                <Typography variant="body2">
                  123 Apply Street, Suite 100
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, backgroundColor: "rgba(255,255,255,0.1)" }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="caption"
            sx={{ display: "block", mb: { xs: 1, sm: 0 } }}
          >
            &copy; {new Date().getFullYear()} LinkedinBuddy. All rights
            reserved.
          </Typography>
          <Box>
            <Link href="#" color="inherit" sx={{ mr: 2, fontSize: "0.75rem" }}>
              Privacy Policy
            </Link>
            <Link href="#" color="inherit" sx={{ fontSize: "0.75rem" }}>
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
