import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const Def = ({ isPopupOpen, setIsPopupOpen }) => {
  const handleClick = () => {
    setIsPopupOpen(false);
    localStorage.setItem("showPopup", "false");
  };
  return (
    <div>
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#333", // Dark background
            color: "#fff", // Light text for contrast
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "1.5rem", textAlign: "center" }}
        >
          How It Works
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            1️⃣ <strong>Go to Settings</strong> – Configure your account based on
            your preferences.
          </Typography>
          <Typography variant="body1" gutterBottom>
            2️⃣ <strong>Customize Your Preferences:</strong>
          </Typography>
          <ul>
            <li>
              <strong>Matching Score:</strong> Define how closely job
              recommendations should match your resume.
            </li>
            <li>
              <strong>AI Model Selection:</strong> Choose the AI model for
              resume generation.
            </li>
            <li>
              <strong>Desired Job Role:</strong> Specify the job role you're
              interested in.
            </li>
            <li>
              <strong>Experience Level:</strong> Select the experience level for
              which you're applying.
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            3️⃣ <strong>Upload Your Resume</strong> in the{" "}
            <strong>Trigger</strong> section.
          </Typography>
          <Typography variant="body1" gutterBottom>
            4️⃣ Click on <strong>“I’m In”</strong> to start the job-matching
            process.
          </Typography>
          <Typography variant="body1" gutterBottom>
            5️⃣ <strong>Wait for 2–4 hours</strong>, and we'll handle the rest!
            🚀
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} variant="contained" color="primary">
            Got It!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Def;
