import { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { saveQuery } from "../api/RapidData";

const faqs = [
  {
    question: "How do I reset my password?",
    answer:
      "You can reset your password by clicking on 'Forgot Password' on the login page.",
  },
  {
    question: "How can I contact support?",
    answer:
      "You can submit your query in the form on this page or email us at support@example.com.",
  },
  {
    question: "What is the response time for queries?",
    answer: "We usually respond within 24-48 hours.",
  },
  {
    question: "How do I update my account details?",
    answer: "Go to 'My Account' > 'Settings' and update your details.",
  },
  {
    question: "Do you offer refunds?",
    answer:
      "Refunds are processed based on our refund policy. Please check our Terms & Conditions.",
  },
  {
    question: "Can I change my subscription plan?",
    answer:
      "Yes, you can upgrade or downgrade your plan from the subscription settings.",
  },
];

const HelpPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    contact: "",
    query: "",
  });
  const [sbMessage, setSbMessage] = useState<string>("");
  const [showSb, setShowSb] = useState<boolean>(false);
  const [error, setError] = useState<{
    email?: string;
    contact?: string;
    query?: string;
  }>({});
  const storedEmail = localStorage.getItem("email");

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateContact = (contact: string) => {
    return /^\d{10}$/.test(contact);
  };

  const validateQuery = (query: string) => {
    return query.length >= 20;
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError({});
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let newErrors: { email?: string; contact?: string; query?: string } = {};

    if (storedEmail) {
      formData.email = storedEmail;
    }

    if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!validateContact(formData.contact)) {
      newErrors.contact = "Contact number must be exactly 10 digits.";
    }
    if (!validateQuery(formData.query)) {
      newErrors.query = "Query must be at least 20 characters long.";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    const userQuery = {
      email: formData?.email,
      contact: formData?.contact,
      query: formData?.query,
    };

    saveQuery(userQuery)
      .then((response: any) => {
        if (response.status == 200) {
          setSbMessage(
            "Your query has been submitted successfully! Our team will investigate the issue and get back to you shortly.",
          );
        } else {
          setSbMessage("Something Went Wrong");
        }
      })
      .catch((error) => {
        setSbMessage("Something Went Wrong");
        console.log("Error while saving query: " + error);
      })
      .finally(() => {
        setShowSb(true);
      });

    setFormData({ email: "", contact: "", query: "" });
  };

  return (
    <Container maxWidth="lg">
      <Box mt={4} mb={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Help & Support
        </Typography>
        <Typography variant="body1" sx={{ color: "rgba(255, 255, 255, 0.9)" }}>
          Let's take a step ahead and help you better.
        </Typography>
      </Box>

      <Box
        maxWidth="lg"
        mx="auto"
        p={4}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.06)", borderRadius: 2 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)", p: 2 }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Frequently Asked Questions
                </Typography>
                {faqs.map((faq, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      mb: 1.6,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ color: "white" }}
                    >
                      {faq.question}
                    </AccordionSummary>
                    <AccordionDetails>{faq.answer}</AccordionDetails>
                  </Accordion>
                ))}
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <form onSubmit={handleSubmit}>
              {!storedEmail && (
                <TextField
                  label="Your Email"
                  name="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!error.email}
                  helperText={error.email}
                  required
                />
              )}

              <TextField
                label="Contact Number"
                name="contact"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.contact}
                onChange={handleChange}
                error={!!error.contact}
                helperText={error.contact}
                required
              />

              <TextField
                label="Your Query"
                name="query"
                fullWidth
                multiline
                rows={6}
                margin="normal"
                variant="outlined"
                value={formData.query}
                onChange={handleChange}
                error={!!error.query}
                helperText={error.query}
                required
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Submit Query
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={showSb}
        autoHideDuration={3000}
        onClose={() => setShowSb(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSb(false)}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {sbMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default HelpPage;
