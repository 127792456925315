import React from "react";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WorkIcon from "@mui/icons-material/Work";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// bk
const AutoApplyHomePage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 3,
          px: 5,
          flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
          gap: 2, // Add gap between the items when stacked vertically
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ color: "#ffffff" }}>
          LinkedIn Buddy
        </Typography>
        <Button
          component={Link}
          to="/auth"
          variant="contained"
          size="large"
          sx={{
            borderRadius: "20px",
            px: 4,
            minWidth: { xs: "100%", sm: "auto" }, // Ensure full width on small screens
            backgroundColor: "#00bcd4", // Cyan shade
            color: "#fff", // White text for contrast
            backdropFilter: "blur(12px)",
            "&:hover": {
              backgroundColor: "#00acc1", // Slightly darker cyan on hover
            },
          }}
        >
          Login/Signup
        </Button>
      </Box>

      {/* Hero Section */}
      <Box
        sx={{
          textAlign: "center",
          py: 8,
          px: 4,
          flexGrow: 1,
        }}
      >
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{ color: "#ffffff", mb: 3 }}
        >
          Our{" "}
          <span
            style={{
              color: "transparent",
              background: "linear-gradient(90deg, #ff7e5f, #feb47b)",
              backgroundSize: "400% 400%",
              WebkitBackgroundClip: "text",
              animation: "gradientMove 5s ease infinite",
            }}
          >
            AI
          </span>{" "}
          finds the most appropriate jobs for you.
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "#f0f0f0", maxWidth: "800px", margin: "0 auto" }}
        >
          Our model tailors your resume to fulfill all job detail matching
          criteria
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/auth"
          size="large"
          sx={{
            mt: 4,
            backgroundColor: "#00bcd4",
            color: "white",
            backdropFilter: "blur(12px)",
            px: 6,
            py: 2,
          }}
        >
          Get Started
        </Button>
      </Box>

      {/* Feature Section */}
      <Box sx={{ py: 8, px: 6 }}>
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          sx={{ color: "#ffffff", mb: 6 }}
        >
          Why Choose Us?
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[
            {
              icon: <AutoAwesomeIcon sx={{ fontSize: 50 }} />,
              title: "Best Possible Jobs",
              text: "Sit back and relax. Our model fetches best possible jobs for you",
            },
            {
              icon: <TrendingUpIcon sx={{ fontSize: 50 }} />,
              title: "Enhance AI",
              text: "choose models based on your budget",
            },
            {
              icon: <WorkIcon sx={{ fontSize: 50 }} />,
              title: "Optimize Resume",
              text: "We optmize your resume, according to job needs",
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  textAlign: "center",
                  minHeight: "300px",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#ffffff",
                  borderRadius: "16px",
                  backdropFilter: "blur(12px)",
                  boxShadow: "0 8px 32px rgba(31, 38, 135, 0.37)",
                }}
              >
                {feature.icon}
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ mt: 2, color: "#ffffff" }}
                >
                  {feature.title}
                </Typography>
                <Typography sx={{ mt: 1, color: "#f0f0f0" }}>
                  {feature.text}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* How It Works Section */}
      <Box sx={{ py: 8, px: 6 }}>
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          sx={{ color: "#ffffff", mb: 6 }}
        >
          How It Works
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[
            {
              icon: <CheckCircleIcon sx={{ fontSize: 50 }} />,
              title: "Enroll",
              text: "Register and sit back while we handle your applications.",
            },
            {
              icon: <SentimentSatisfiedAltIcon sx={{ fontSize: 50 }} />,
              title: "Choose AI",
              text: "choose which model you want to pick, based on your budget",
            },
            {
              icon: <AutoAwesomeIcon sx={{ fontSize: 50 }} />,
              title: "upload your resume",
              text: "We will tailor your resume, according to job needs ",
            },
            {
              icon: <TrendingUpIcon sx={{ fontSize: 50 }} />,
              title: "Statistics & Tracking",
              text: "Track all your applications and modified resumes in one place.",
            },
          ].map((step, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={2}
                sx={{
                  p: 4,
                  textAlign: "center",
                  minHeight: "300px",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#ffffff",
                  borderRadius: "16px",
                  backdropFilter: "blur(12px)",
                  boxShadow: "0 8px 32px rgba(31, 38, 135, 0.37)",
                }}
              >
                {step.icon}
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ mt: 2, color: "#ffffff" }}
                >
                  {step.title}
                </Typography>
                <Typography sx={{ mt: 1, color: "#f0f0f0" }}>
                  {step.text}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AutoApplyHomePage;
