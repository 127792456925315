import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { AuthContext } from "../api/AuthProvider";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";

const gradientAnimation = `
  @keyframes gradientMove {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isLoggedIn, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {}, [isLoggedIn]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          to="/home"
          onClick={toggleDrawer(false)}
        >
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/about"
          onClick={toggleDrawer(false)}
        >
          <ListItemText primary="About" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/buyUsCoffee"
          onClick={toggleDrawer(false)}
        >
          <ListItemText primary="Bus Us Coffee" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/help"
          onClick={toggleDrawer(false)}
        >
          <ListItemText primary="Help" />
        </ListItem>

        {isLoggedIn && (
          <ListItem button onClick={logout}>
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          background: "rgba(255, 255, 255, 0.2)", // Adjust the color and opacity as needed
          backdropFilter: "blur(10px)", // Adjust the blur radius as needed
          borderRadius: "8px", // Optional: Add some rounding to the corners
          border: "1px solid rgba(255, 255, 255, 0.2)", // Optional: Add a subtle border
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <style>{gradientAnimation}</style>
          <Typography
            variant="h6"
            sx={{
              fontSize: "150%",
              fontWeight: "bold",
              flexGrow: 1,
              display: { xs: "block", md: "block" },
              textAlign: { xs: "center", md: "left" },
              background:
                "linear-gradient(90deg, #FF5733, #FFC300, #DAF7A6, #33FFBD, #5733FF)",
              backgroundSize: "400% 100%",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              animation: "gradientMove 5s infinite",
            }}
          >
            Linkedin Buddy
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button color="inherit" component={Link} to="/home">
              Home
            </Button>
            <Button color="inherit" component={Link} to="/about">
              About
            </Button>

            <Button color="inherit" component={Link} to="/help">
              Help
            </Button>
            <Button color="inherit" component={Link} to="/buyUsCoffee">
              Buy us Coffee
            </Button>

            {isLoggedIn && (
              <Button
                color="secondary"
                variant="contained"
                onClick={logout}
                sx={{ ml: 2 }}
              >
                Logout
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;
