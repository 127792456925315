import React, { useState, useEffect, useRef } from "react";
import {
  checkOrMakeResume,
  downloadFileS3,
  downloadFileS3Blob,
  downloadFileS3DiffFileName,
  fetchAllCurrentJobs,
  resumeStatus,
  updatedJobs,
} from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Link,
  Tooltip,
  Grid,
  LinearProgress,
} from "@mui/material";
import NoticePopup from "../../../common/NoticePopup";
import { getValue } from "../../../api/Redis";
import CaptchaBypass from "../../../common/CaptchaBypass";

const options = ["1", "2", "3", "4", "5", "6"];
interface Job {
  jobId: string;
  jobWanted: string;
  jobTitle: string;
  companyName: string;
  matchingScore: string;
  createdAt: string;
  status: string;
  model: string;
}

interface CompanyCardProps {
  job: Job;
}

function prepareUrl(jobId: string): string {
  return `https://www.linkedin.com/jobs/collections/recommended/?currentJobId=${jobId}`;
}

const getRandomBrightColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = Math.floor(Math.random() * 156 + 100).toString(16);
    color += value.padStart(2, "0");
  }
  return color;
};

const AutoApplyStatistics: React.FC = () => {
  const constmessage =
    "Data fetched successfully. It may take up to 4 hours for changes to reflect in your account.";
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState("Fetching...");
  const [displayNotice, setDisplayNotice] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState(constmessage);
  const [imageFile, setImageFile] = useState<string | void | null>(null);
  const [open, setOpen] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const jobData = useRef<Job[]>([]);

  useEffect(() => {
    setLoading(true);

    const fetchJobs = async () => {
      try {
        const listOfJobs = await updatedJobs();
        const parsedJobs = listOfJobs.map((job: Job) => ({
          jobId: job.jobId,
          jobWanted: job.jobWanted,
          jobTitle: job.jobTitle,
          companyName: job.companyName,
          matchingScore: job.matchingScore,
        }));
        jobData.current = parsedJobs;
        setJobs(parsedJobs);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async () => {
      try {
        const result = await resumeStatus();
        var newListOfJobs = jobData.current;
        if (result) {
          newListOfJobs = newListOfJobs.map((job) => {
            const foundJob = result.find((res: any) => res.jobId === job.jobId);
            if (foundJob) {
              return {
                ...job,
                status: foundJob.status,
                createdAt: foundJob.createdAt,
                model: foundJob.modelName,
              };
            }
            return job;
          });
          setJobs(newListOfJobs);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch jobs initially
    fetchJobs().then(fetchData);

    // Set interval to update job statuses every 30 seconds
    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, []);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleFetchLatestJobs = async () => {
    try {
      setLoading(true);
      const delay = (ms: any) =>
        new Promise((resolve) => setTimeout(resolve, ms));

      // Fetching Current Jobs
      const fetchJobData = await fetchAllCurrentJobs();
      if (
        fetchJobData == "fetched successfully 1" ||
        fetchJobData == "fetched successfully 2"
      ) {
        setNoticeMessage(
          "Alreay Latest Data, Soon It will Reflect In Your Account",
        );
        setDisplayNotice(true);
        setLoading(false);
        return;
      }
      setLoadingMessage("Bot Checking...");
      let retries = 15;
      while (retries > 0) {
        await delay(5000);
        if (retries % 2 != 0) {
          setLoadingMessage("ETA 2 min...");
        } else {
          setLoadingMessage("Bot Checking...");
        }
        const email: string = localStorage.getItem("email") || "";
        const redis_response_raw = await getValue(email);
        const redis_response = redis_response_raw?.[email];

        // If redis response has captcha Create Puzzle
        if (redis_response?.haveCaptcha) {
          setLoadingMessage("Creating Puzzle...");
          const pngFile = await downloadFileS3Blob(email, "captcha");
          if (pngFile) {
            setImageFile(pngFile);
            setOpen(true);
            break;
          }
        }

        // If Login success
        const loginMessage = redis_response?.login;
        if (loginMessage == "SUCCESS") {
          setNoticeMessage(constmessage);
          break;
        } else if (loginMessage == "FAILED") {
          break;
        }
      }
      if (retries == 0) {
        setNoticeMessage("OOps something Went Wrong, Try After Some Time");
      }
      setDisplayNotice(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const CompanyCard: React.FC<CompanyCardProps> = ({ job }) => {
    const status = job?.status;
    const [companyCardStatus, setCompanyCardStatus] = useState(status);
    const [isEnhancing, setIsEnhancing] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownloadSettings = async (jobId: string) => {
      setIsEnhancing(true);
      await checkOrMakeResume(jobId);
      setIsEnhancing(false);
      setCompanyCardStatus("PENDING");
    };

    const handleDownload = async () => {
      setIsDownloading(true);
      var modelMap = job.model === "TEREX-BASIC-1.2v" ? "GEMINI" : "GPT";
      var fileName = localStorage.getItem("email") + "_" + job.jobId;
      await downloadFileS3DiffFileName(
        fileName + "_" + modelMap,
        fileName,
        "generated-resume",
      );
      setIsDownloading(false);
    };

    const LinearLoader = () => {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          p={2}
        >
          <Typography variant="h6" mb={1} color="primary">
            Generating Resume, please wait...
          </Typography>
          <Box width="80%" position="relative">
            <LinearProgress
              sx={{ height: 8, borderRadius: 5, backgroundColor: "#f0f0f0" }}
            />
          </Box>
        </Box>
      );
    };

    return (
      <Card
        sx={{
          marginBottom: "16px",
          borderRadius: "8px",
          color: "white",
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <Link
              href={prepareUrl(job.jobId)}
              target="_blank"
              rel="noopener"
              sx={{
                color: getRandomBrightColor(),
                textDecoration: "none",
                "&:hover": {
                  color: "#1abc9c",
                  textDecoration: "underline",
                },
              }}
            >
              {job.companyName}
            </Link>
          </Typography>

          {/* Table-like Layout without table lines */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "100px 1fr",
              gap: "8px",
              marginBottom: "8px",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Job Wanted:
            </Typography>
            <Typography variant="body1">{job.jobWanted}</Typography>

            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Job Title:
            </Typography>
            <Typography variant="body1">{job.jobTitle}</Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Matching Score: {job.matchingScore}
              </Typography>
            </Box>
            {companyCardStatus === "PENDING" ? (
              <LinearLoader />
            ) : status === "DONE" ? (
              <Button
                onClick={() => handleDownload()}
                disabled={isDownloading}
                sx={{
                  backgroundColor: "#2ecc71",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#27ae60",
                  },
                  marginTop: "8px",
                }}
              >
                {isDownloading ? "Downloading..." : "Download"}
              </Button>
            ) : status === "FAILED" ? (
              <Typography color="error">Failed</Typography>
            ) : (
              <Button
                onClick={() => handleDownloadSettings(job.jobId)}
                disabled={isEnhancing}
                sx={{
                  backgroundColor: "#3498db",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#2980b9",
                  },
                  marginTop: "8px",
                }}
              >
                {isEnhancing ? "Enhancing..." : "Enhance Resume"}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      {loading && <LoaderCenter message="Fetching..." />}
      <CaptchaBypass
        imageFile={imageFile}
        options={options}
        open={open}
        onClose={handleClose}
      />
      <NoticePopup
        message={noticeMessage}
        displayNotice={displayNotice}
        setDisplayNotice={setDisplayNotice}
      />
      {loading && <LoaderCenter message={loadingMessage} />}
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "3%" }}
      >
        <Button
          onClick={handleFetchLatestJobs}
          sx={{
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            },
          }}
        >
          Fetch Latest Jobs
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
        {jobs.length > 0 ? (
          jobs.map((job) => <CompanyCard key={job.jobId} job={job} />)
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Card
              sx={{
                width: "90%",
                padding: 3,
                textAlign: "center",
                boxShadow: 3,
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  No Jobs Available
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  If you are not seeing any jobs here, possible reasons could
                  be:
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="ul"
                  sx={{ textAlign: "left", mt: 1 }}
                >
                  <li>
                    Our AI model is actively searching for jobs. Please wait at
                    least 4 hours.
                  </li>
                  <li>Try lowering the matching score in settings.</li>
                  <li>Ensure your profile settings are fully completed.</li>
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AutoApplyStatistics;
