import React, { useState } from "react";
import { Box } from "@mui/material";
import AutoApplyNavBar from "./AutoApplyNavBar";

const AutoApplyMainPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        // backgroundImage: `url(/colorRainBackground.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxSizing: "border-box",
        justifyContent: "center",
      }}
    >
      <AutoApplyNavBar />
    </Box>
  );
};

export default AutoApplyMainPage;
