"use client";

import type React from "react";

import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Coffee,
  ListIcon as Favorite,
  Share,
  CopyIcon as ContentCopy,
} from "lucide-react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`payment-tabpanel-${index}`}
      aria-labelledby={`payment-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BuyUsCoffee() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [customAmount, setCustomAmount] = useState<string>("50");
  const [tabValue, setTabValue] = useState(0);
  const upiId = "belalazam3@okicici";

  const predefinedAmounts = [
    { value: "25", label: "₹25" },
    { value: "50", label: "₹50" },
    { value: "100", label: "₹100" },
    { value: "200", label: "₹200" },
    { value: "500", label: "₹500" },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleCopyUPI = () => {
    navigator.clipboard.writeText(upiId);
  };

  const handlePayWithUPI = () => {
    window.open(
      `upi://pay?pa=${upiId}&pn=Belal%20Azam&am=${customAmount}&cu=INR`,
      "_blank",
    );
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Avatar
          sx={{
            width: 64,
            height: 64,
            bgcolor: "primary.light",
            mx: "auto",
            mb: 2,
          }}
        >
          <Coffee size={32} />
        </Avatar>
        <Typography variant="h3" component="h1" gutterBottom>
          Buy Us a Coffee
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ maxWidth: 600, mx: "auto" }}
        >
          If you enjoy our platform, consider supporting our work with a small
          contribution.
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ mb: 4, alignItems: "stretch" }}>
        {[
          {
            title: "Why Support?",
            content: (
              <List sx={{ width: "100%" }}>
                <ListItem>
                  <ListItemIcon>
                    <Coffee size={20} color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Help us enhance our job-matching platform" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Favorite size={20} color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Support independent developers building tools" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Share size={20} color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Enable us to continue creating innovative solutions" />
                </ListItem>
              </List>
            ),
            centered: false,
          },
          {
            title: "Choose Amount",
            content: (
              <>
                <Grid container spacing={1} sx={{ mb: 3 }}>
                  {predefinedAmounts.map((amount) => (
                    <Grid item xs={4} key={amount.value}>
                      <Button
                        variant={
                          customAmount === amount.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => setCustomAmount(amount.value)}
                        fullWidth
                      >
                        {amount.label}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                <TextField
                  label="Custom Amount (₹)"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  value={customAmount}
                  onChange={(e) => setCustomAmount(e.target.value)}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 1 }}
                />
              </>
            ),
            centered: true,
          },
        ].map(({ title, content, centered }, index) => (
          <Grid item xs={12} md={6} key={index} sx={{ display: "flex" }}>
            <Card
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: centered ? "center" : "flex-start",
                  justifyContent: centered ? "center" : "flex-start",
                  textAlign: centered ? "center" : "left",
                  width: "100%",
                }}
              >
                <Typography variant="h5" component="h2" gutterBottom>
                  {title}
                </Typography>
                {content}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Card elevation={2} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Payment Options
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="payment options tabs"
              centered
            >
              <Tab label="UPI" />
              <Tab label="QR Code" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="subtitle1" gutterBottom>
                UPI ID:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  mb: 2,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    px: 2,
                    py: 1,
                    bgcolor: "action.hover",
                    borderRadius: 1,
                    fontFamily: "monospace",
                    fontSize: "1.1rem",
                  }}
                >
                  {upiId}
                </Paper>
                <IconButton onClick={handleCopyUPI} size="small">
                  <ContentCopy size={18} />
                </IconButton>
              </Box>
              {isMobile ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePayWithUPI}
                  sx={{ mt: 2 }}
                >
                  Pay ₹{customAmount} with UPI
                </Button>
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Use your phone to scan the QR code or copy the UPI ID
                </Typography>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  border: "4px solid",
                  borderColor: "primary.light",
                  borderRadius: 2,
                  bgcolor: "white",
                  mb: 2,
                }}
              >
                {/* Placeholder for QR code - in a real app, generate this dynamically */}
                <Box
                  sx={{
                    width: 200,
                    height: 200,
                    backgroundImage:
                      "url(/sylwave_qr_code.jpeg?height=200&width=200)",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Paper>
              <Typography variant="body2" color="text.secondary" align="center">
                Scan this QR code with any UPI app to pay ₹{customAmount}
              </Typography>
            </Box>
          </TabPanel>
        </CardContent>
      </Card>

      <Box sx={{ textAlign: "center" }}>
        <Typography variant="body2" color="text.secondary">
          Thank you for your support! It means a lot to us.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Have questions? Contact us at{" "}
          <Box
            component="a"
            href="mailto:contact@example.com"
            sx={{
              color: "primary.main",
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            support@sylwave.com
          </Box>
        </Typography>
      </Box>
    </Container>
  );
}
