"use client";

import type React from "react";
import { useState, useEffect, type ChangeEvent } from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
  Paper,
  Alert,
  Fade,
  Container,
  Tooltip,
  Divider,
  CircularProgress,
  Chip,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  CheckCircle as CheckCircleIcon,
  Settings as SettingsIcon,
  InsertDriveFile as InsertDriveFileIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material";
import {
  saveFile,
  updateActiveStatus,
  retrieveAutoApplyUserData,
} from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import TypingGreetings from "../../../common/TypingGreetings";
import NoticePopup from "../../../common/NoticePopup";
import QuestionMarkButton from "../../../common/QuestionMarkButton";
import Def from "./Def";

interface AutoApplyTriggerProps {
  setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
}

const AutoApplyTrigger: React.FC<AutoApplyTriggerProps> = ({
  setActiveComponent,
}) => {
  const [latexFile, setLatexFile] = useState<File | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("No file chosen");
  const [optButton, setOptButton] = useState("I Am In");
  const [displayNotice, setDisplayNotice] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState("");
  const [jobLevel, setJobLevel] = useState<string | null>(null);
  const [jobWanted, setJobWanted] = useState<string | null>(null);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(
    localStorage.getItem("showPopup") !== "false",
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response: any = await retrieveAutoApplyUserData();
        if (response.status === 200) {
          const { userResume, isActive, jobWanted, jobLevel } = response.data;
          setJobLevel(jobLevel);
          setJobWanted(jobWanted);

          setOptButton(isActive ? "I Am Out" : "I Am In");
          if (userResume) {
            setLatexFile(new File([], userResume));
            setFileName(userResume);
            setFileUploaded(true);
          }
        }
      } catch (err) {
        console.error("Error:", err);
        setError("Failed to load your data. Please refresh the page.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIsButtonDisabled(loading || !latexFile || !jobLevel || !jobWanted);
  }, [loading, latexFile, jobLevel, jobWanted]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setLatexFile(file);
      setFileName(file.name);
      setFileUploaded(true);

      setUploading(true);
      setError(null);
      try {
        await saveFile(file.name, file, "user-resume");
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      } catch {
        setError("Failed to upload file. Please try again.");
        setFileUploaded(false);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleCustomButtonClick = () => {
    document.getElementById("file-input")?.click();
  };

  const handleOptButton = async () => {
    setLoading(true);
    try {
      const newStatus = optButton === "I Am In";
      await updateActiveStatus(newStatus);

      setNoticeMessage(
        newStatus
          ? "Great! We'll start matching you with jobs based on your resume. It may take up to four hours to process."
          : "You've opted out of auto-apply. You can opt back in anytime.",
      );
      setDisplayNotice(true);
      setOptButton(optButton === "I Am In" ? "I Am Out" : "I Am In");
    } catch (err) {
      setNoticeMessage("Oops! Something went wrong. Please try again later.");
      setDisplayNotice(true);
      console.error("Error updating status:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  const navigateToSettings = () => {
    setActiveComponent("settings");
  };

  const settingsIncomplete = !jobLevel || !jobWanted;

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <QuestionMarkButton onClick={handleClick} />
        <TypingGreetings
          duration={15000}
          text={`Hello ${localStorage.getItem("name") || ""}!`}
        />
      </Box>
      {showPopup && (
        <Def isPopupOpen={showPopup} setIsPopupOpen={setShowPopup} />
      )}
      <Container maxWidth="sm">
        <Box
          sx={{
            position: "relative",
            minHeight: "100vh",
            pb: 4,
            textAlign: "center",
          }}
        >
          <NoticePopup
            message={noticeMessage}
            displayNotice={displayNotice}
            setDisplayNotice={setDisplayNotice}
          />
          <Grid
            container
            justifyContent="center"
            sx={{
              px: 2,
            }}
          >
            <Grid item xs={12}>
              <Paper
                elevation={6}
                sx={{
                  borderRadius: "16px",
                  overflow: "hidden",
                  bgcolor: "background.paper",
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                  }}
                >
                  <Typography variant="h5" component="h1" fontWeight="bold">
                    Upload Your Resume
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1, opacity: 0.9 }}>
                    We'll match your skills with relevant jobs on LinkedIn
                  </Typography>
                </Box>

                <Box sx={{ width: "100%", px: 3 }}>
                  {loading ? (
                    <LoaderCenter message="Loading your profile data..." />
                  ) : (
                    <>
                      {settingsIncomplete && (
                        <Alert
                          severity="warning"
                          sx={{ m: 2 }}
                          action={
                            <Button
                              color="inherit"
                              size="small"
                              onClick={navigateToSettings}
                              startIcon={<SettingsIcon />}
                            >
                              Update Settings
                            </Button>
                          }
                        >
                          Please complete your job preferences in Settings
                          before activating auto-apply
                        </Alert>
                      )}

                      {showSuccess && (
                        <Fade in={showSuccess}>
                          <Alert severity="success" sx={{ mb: 3 }}>
                            Resume uploaded successfully!
                          </Alert>
                        </Fade>
                      )}

                      {error && (
                        <Alert severity="error" sx={{ mb: 3 }}>
                          {error}
                        </Alert>
                      )}

                      <Card
                        variant="outlined"
                        sx={{
                          borderRadius: 2,
                          transition: "all 0.3s",
                          "&:hover": {
                            boxShadow: 3,
                          },
                          m: 2,
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                              color: "primary.main",
                            }}
                          >
                            <CloudUploadIcon sx={{ mr: 1 }} />
                            <Typography variant="h6" component="h2">
                              Resume Upload
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 3 }}
                          >
                            Upload your latest resume in PDF format. We'll
                            analyze your skills and experience to find matching
                            jobs on LinkedIn.
                          </Typography>

                          <Box
                            sx={{
                              p: 3,
                              border: "1px dashed",
                              borderColor: "divider",
                              borderRadius: 1,
                              textAlign: "center",
                              mb: 2,
                              bgcolor: "background.default",
                            }}
                          >
                            {uploading ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <CircularProgress size={40} sx={{ mb: 2 }} />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Uploading your resume...
                                </Typography>
                              </Box>
                            ) : (
                              <>
                                {fileUploaded ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CheckCircleIcon
                                      color="success"
                                      sx={{ mr: 1 }}
                                    />

                                    <Tooltip title={fileName} arrow>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          color: "success.main",
                                          fontWeight: "medium",
                                          cursor: "pointer", // Optional: Makes it clear it's interactive
                                        }}
                                      >
                                        {(() => {
                                          const maxChars = 10;
                                          const parts = fileName.split(".");
                                          const extension =
                                            parts.length > 1
                                              ? `.${parts.pop()}`
                                              : "";
                                          const baseName = parts.join(".");
                                          return baseName.length > maxChars
                                            ? `${baseName.substring(0, maxChars)}...${extension}`
                                            : fileName;
                                        })()}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InsertDriveFileIcon
                                      sx={{
                                        fontSize: 48,
                                        color: "text.secondary",
                                        mb: 1,
                                      }}
                                    />
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      Drag and drop your PDF resume here or
                                      click to browse
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>

                          <input
                            type="file"
                            accept=".pdf"
                            id="file-input"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </CardContent>

                        <CardActions sx={{ p: 2, pt: 0 }}>
                          <Button
                            variant="contained"
                            onClick={handleCustomButtonClick}
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                            disabled={uploading}
                            sx={{
                              borderRadius: "8px",
                              py: 1.5,
                              textTransform: "none",
                              fontWeight: "medium",
                            }}
                          >
                            {fileUploaded ? "Replace Resume" : "Choose File"}
                          </Button>
                        </CardActions>
                      </Card>

                      <Divider sx={{ my: 3 }}>
                        <Chip label="Auto-Apply Settings" />
                      </Divider>

                      <Box sx={{ textAlign: "center", mb: 2 }}>
                        <Typography
                          variant="body1"
                          sx={{ mb: 1, fontWeight: "medium" }}
                        >
                          Ready to start receiving job matches?
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mb: 2,
                          }}
                        >
                          <InfoOutlinedIcon
                            sx={{ color: "info.main", mr: 1, fontSize: 20 }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            Make sure your settings are complete for best
                            matching results
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            isButtonDisabled
                              ? "Complete settings and upload resume first"
                              : optButton === "I Am In"
                                ? "Start receiving job matches"
                                : "Stop receiving job matches"
                          }
                        >
                          <span>
                            <Button
                              onClick={handleOptButton}
                              variant="contained"
                              disabled={isButtonDisabled}
                              size="large"
                              sx={{
                                backgroundColor:
                                  optButton === "I Am Out"
                                    ? "error.main"
                                    : "success.main",
                                color: "#fff",
                                px: 4,
                                py: 1.5,
                                borderRadius: "12px",
                                fontWeight: "bold",
                                fontSize: "16px",
                                textTransform: "none",
                                boxShadow: 4,
                                transition: "all 0.3s",
                                "&:hover": {
                                  backgroundColor:
                                    optButton === "I Am Out"
                                      ? "error.dark"
                                      : "success.dark",
                                  transform: "translateY(-2px)",
                                  boxShadow: 6,
                                },
                                minWidth: 200,
                              }}
                            >
                              {optButton}
                            </Button>
                          </span>
                        </Tooltip>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 2 }}
                        >
                          {optButton === "I Am In"
                            ? "Click to start receiving job matches based on your resume"
                            : "Click to stop receiving job matches"}
                        </Typography>

                        <Button
                          variant="text"
                          size="small"
                          startIcon={<SettingsIcon />}
                          onClick={navigateToSettings}
                          sx={{ m: 2 }}
                        >
                          Update Job Preferences
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AutoApplyTrigger;
