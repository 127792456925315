import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Grid,
  Slider,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@mui/material";
import {
  save_settings,
  retrieve_settings,
  requestNewJobRole,
} from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import SB from "../../../common/Snackbar";
import { getValue } from "../../../api/Redis";

interface FormValues {
  jobWanted: string;
  jobLevel: string;
  matchingScore: number;
  selectedModel?: string;
}

const AutoApplyMainPage: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    jobLevel: "",
    jobWanted: "",
    matchingScore: 90,
    selectedModel: "TEREX-BASIC-1.2v",
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [sbMessage, setSbMessage] = useState<string>("");
  const [showSb, setShowSb] = useState<boolean>(false);
  const [jobRoles, setJobRoles] = useState<string[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [newJobRole, setNewJobRole] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    const getSettings = async () => {
      try {
        const response = await retrieve_settings();
        if (response.status === 200) {
          const { jobWanted, jobLevel, matchingScore, currentModel } =
            response.data;

          setFormValues((prev) => ({
            jobWanted: jobWanted || prev.jobWanted,
            jobLevel: jobLevel || prev.jobLevel,
            matchingScore: matchingScore || 90,
            selectedModel:
              currentModel || prev.selectedModel || "TEREX-BASIC-1.2v",
          }));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchJobRoles = async () => {
      try {
        const response = await getValue("jobRoles");
        if (response?.jobRoles?.data) {
          setJobRoles(response.jobRoles.data);
        } else {
          setJobRoles([]);
        }
      } catch (error) {
        console.error("Error fetching job roles:", error);
        setJobRoles([]);
      }
    };

    Promise.all([getSettings(), fetchJobRoles()]).finally(() =>
      setShowLoader(false),
    );
  }, []);

  useEffect(() => {
    if (!formValues.selectedModel) {
      setFormValues((prev) => ({
        ...prev,
        selectedModel: "TEREX-BASIC-1.2v",
      }));
    }
  }, [formValues.selectedModel]);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    setFormValues({ ...formValues, matchingScore: value as number });
  };

  const handleExperienceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedExperience = event.target.name;
    setFormValues({ ...formValues, jobLevel: selectedExperience });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setShowSb(false);
    setIsEditing(false);
    setShowLoader(true);
    const linkedinRequest = {
      jobWanted: formValues?.jobWanted,
      jobLevel: formValues?.jobLevel,
      matchingScore: formValues?.matchingScore,
      selectedModel: formValues?.selectedModel,
    };

    save_settings(linkedinRequest)
      .then((response: any) => {
        if (response.status == 200) {
          setSbMessage("Saved Successfully");
        } else {
          setSbMessage("Something Went Wrong");
        }
      })
      .catch((error) => {
        setSbMessage("Something Went Wrong");
      })
      .finally(() => {
        setShowLoader(false);
        setShowSb(true);
      });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
      {showSb && <SB message={"Saved Successfully"} method={"pass"}></SB>}
      <Box
        sx={{
          maxWidth: 500,
          margin: "0px 20px",
          padding: 4,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.7)",
          borderRadius: 2,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          color: "white",
        }}
      >
        {showLoader && <LoaderCenter message="loading..." />}
        <Typography variant="h4" gutterBottom align="center">
          Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Matching Score: {formValues.matchingScore}%
            </Typography>
            <Slider
              value={formValues.matchingScore}
              onChange={handleSliderChange}
              aria-labelledby="matching-score-slider"
              step={1}
              marks
              min={0}
              max={100}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              disabled={!isEditing}
              error={!formValues.selectedModel}
            >
              <Select
                value={formValues.selectedModel}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    selectedModel: e.target.value,
                  })
                }
                sx={{
                  color: "white",
                  "& .MuiSelect-icon": { color: "white" },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "black", // Makes the dropdown background solid black
                      color: "white", // Ensures text is visible
                    },
                  },
                }}
              >
                <MenuItem value="TEREX-ADVANCE-1.8v">
                  TEREX-ADVANCE-1.8v
                </MenuItem>
                <MenuItem value="TEREX-BASIC-1.2v">TEREX-BASIC-1.2v</MenuItem>
              </Select>
              {!formValues.selectedModel && (
                <FormHelperText>Please select a model</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* dropdown to select job roles */}
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={jobRoles || []}
              inputValue={formValues.jobWanted || ""}
              onInputChange={(_, value) => {
                if (value === "+ REQ NEW JOB ROLE") {
                  setOpenDialog(true);
                } else {
                  setFormValues((prev) => ({ ...prev, jobWanted: value }));
                }
              }}
              disabled={!isEditing}
              filterOptions={(options, { inputValue }) => {
                const filtered = options.filter((option) =>
                  option.toLowerCase().includes(inputValue.toLowerCase()),
                );
                return [...filtered, "+ REQ NEW JOB ROLE"];
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Wanted"
                  variant="outlined"
                  InputLabelProps={{ style: { color: "white" } }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: "white" },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
              )}
              getOptionLabel={(option) => option || ""}
              noOptionsText="No options found"
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "black", // Dark background for dropdown items
                    color: "white", // White text for contrast
                  },
                },
              }}
            />

            {/* Request New Job Role Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>Request New Job Role</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="New Job Role"
                  fullWidth
                  value={newJobRole}
                  onChange={(e) => setNewJobRole(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <Button
                  onClick={async () => {
                    try {
                      await requestNewJobRole(newJobRole);
                      setShowSuccess(true);
                      // <SB method={showSuccess ? "pass" : "error"} message="Successfully3 requested new job role" />
                      setNewJobRole("");
                    } catch (error) {
                      console.error("Error requesting new job role:", error);
                      setSbMessage("Something Went Wrong");
                    }
                  }}
                >
                  Request
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar
              open={showSuccess}
              autoHideDuration={3000}
              onClose={() => setShowSuccess(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={() => setShowSuccess(false)}
                variant="filled"
                severity="success"
                sx={{ width: "100%" }}
              >
                {"Successfully requested new job role"}
              </Alert>
            </Snackbar>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Experience:
            </Typography>
            <FormGroup>
              {[
                "Internship",
                "Entry level",
                "Associate",
                "Mid-Senior level",
                "Director",
                "Executive",
              ].map((exp) => (
                <FormControlLabel
                  key={exp}
                  control={
                    <Checkbox
                      checked={formValues.jobLevel === exp}
                      onChange={handleExperienceChange}
                      name={exp}
                      disabled={!isEditing}
                    />
                  }
                  label={exp}
                  sx={{ color: "white" }}
                />
              ))}
            </FormGroup>
          </Grid>

          <Grid item xs={12} container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditClick}
              disabled={isEditing}
              sx={{
                backgroundColor: "#9C27B0",
                "&:hover": {
                  backgroundColor: "#7B1FA2",
                },
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSaveClick}
              disabled={!isEditing}
              sx={{
                backgroundColor: "#9C27B0",
                "&:hover": {
                  backgroundColor: "#7B1FA2",
                },
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AutoApplyMainPage;
